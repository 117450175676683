import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import styles from './styles.module.css';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { lineGraphLayout } from '../../../utils/plots/style/layouts';
import { Daterange, getExpandedPlotParams, getMostUsedBlocksParams, setParams } from '../../../params-analyzer/marty-blocks';


type Props = {
  title?: string,
};

const TimelinePlot = ({ title }: Props) => {
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [clientRawData, setClientRawData] = useState<any | null>(null);
  const clientIdParamChangedByUser = useRef(false);
  const [selectedDateRange, setSelectedDateRange] = useState<Daterange>({ start: "2020-01-01", end: "2030-01-01" });
  const [onlyFetchFeedbackEntries, setOnlyFetchFeedbackEntries] = useState(false);
  const dateRangeParamChangedByUser = useRef(false);

  useEffect(() => {
    // Fetch client IDs
    const fetchData = async () => {
      const clientIds = await fetchClientIds(selectedDateRange, onlyFetchFeedbackEntries);
      setClientIds(clientIds);
      clientIds[0] && setSelectedClientId(clientIds[0]);
    }
    fetchData();
  }, [selectedDateRange, onlyFetchFeedbackEntries]);

  useEffect(() => {
    if (clientIds.length === 0) return;
    const asyncFunc = async () => {
      // busy wait for the params to be set
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const params = getMostUsedBlocksParams();
      const expandedPlot = getExpandedPlotParams();
      if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
        if (params.clientId) {
          setSelectedClientId(params.clientId);
        } else {
          setParams({ clientId: selectedClientId });
        }
        if (params.dateRange) {
          setSelectedDateRange(params.dateRange as Daterange);
        } else {
          const selectedDateRangeString = `${selectedDateRange.start} - ${selectedDateRange.end}`;
          setParams({ dateRange: selectedDateRangeString });
        }
      }
    }
    asyncFunc();
  }, [clientIds]);

  useEffect(() => {
    const expandedPlot = getExpandedPlotParams();
    if (expandedPlot.expandedPlot === title?.replaceAll(" ", "-").toLowerCase()) {
      clientIdParamChangedByUser.current && setParams({ clientId: selectedClientId });
      if (dateRangeParamChangedByUser.current) {
        const selectedDateRangeString = `${selectedDateRange.start} - ${selectedDateRange.end}`;
        setParams({ dateRange: selectedDateRangeString });
      }
    }
  }, [selectedClientId, selectedDateRange]);

  useEffect(() => {
    if (selectedClientId) {
      const fetchData = async () => {
        const data = await fetchClientData(selectedClientId);
        setClientRawData(data);
      }
      fetchData();
    }
  }, [selectedClientId]);

  const onClientIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    clientIdParamChangedByUser.current = true;
    setSelectedClientId(e.target.value);
  }

  const onDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dateRangeParamChangedByUser.current = true;
    const id = e.target.id;
    const value = e.target.value;
    setSelectedDateRange((prev) => {
      return {
        ...prev,
        [id]: value
      }
    });
  }

  const processClientData = (data: any) => {
    const startDate = new Date(selectedDateRange.start);
    const endDate = new Date(selectedDateRange.end);
    const events = Object.values(data)
      .map((entry: any) => ({
        date: new Date(entry.timestamp),
        event: entry.type,
        details: {
          ...entry,
          timestamp: undefined,
          type: undefined,
        },
      }))
      .filter(event => event.date >= startDate && event.date <= endDate);
    return events;
  };

  const plotData = clientRawData ? processClientData(clientRawData) : [];

  return (
    !!!clientRawData ? <LoadingSpinner /> :
      <>
        <div className={styles.parameters}>
          <div className={styles.topN}>
            <label htmlFor="clientId">Select Client ID: </label>
            <select
              id="clientId"
              value={selectedClientId || ''}
              onChange={onClientIdChange}
            >
              {clientIds.map(clientId => (
                <option key={clientId} value={clientId}>{clientId}</option>
              ))}
            </select>
          </div>
          <div className={styles.topN}>
            <label htmlFor="start">Start: </label>
            <input
              type="date"
              id="start"
              value={selectedDateRange.start}
              onChange={onDateRangeChange}
              className={styles.dateInput}
            />
            <label htmlFor="end">End: </label>
            <input
              type="date"
              id="end"
              value={selectedDateRange.end}
              onChange={onDateRangeChange}
              className={styles.dateInput}
            />
          </div>
          <div className={styles.topN}>
            <label htmlFor="onlyFetchFeedbackEntries">Only Fetch Feedback Entries: </label>
            <input
              type="checkbox"
              id="onlyFetchFeedbackEntries"
              checked={onlyFetchFeedbackEntries}
              onChange={() => setOnlyFetchFeedbackEntries(!onlyFetchFeedbackEntries)}
            />
          </div>
        </div>
        {plotData.length > 0 && (
          <Plot
            style={{ width: "100%", height: "100%" }}
            data={[
              {
                x: plotData.map(d => d.date),
                y: plotData.map(d => ''),
                text: plotData.map(d => d.event),
                type: 'scatter',
                mode: 'text+markers',
                textposition: 'top center',
                marker: { color: 'red', size: 10 },
                hoverinfo: 'text',
                hovertext: plotData.map(d =>
                  JSON.stringify(d.details, null, 2)
                    .replace(/{|}/g, "")           // Remove curly braces
                    .replace(/^\s*\n/gm, "")        // Remove any extra blank lines
                    .replaceAll(",", "<br>")// Remove commas
                ),
                hovertemplate: [
                  "<b>Details:</b><br>%{hovertext}"
                ].join("<br>"),
              },
            ]}
            layout={{
              ...lineGraphLayout,
              xaxis: {
                ...lineGraphLayout.xaxis,
                title: 'Time',
                // rangeslider: processedData ? rangeSlider(processedData.smallerDate, processedData.largerDate) : {},
              },
              yaxis: {
                ...lineGraphLayout.yaxis,
                title: 'Event',
              },
            }}
          />
        )}
      </>
  );
};

export default TimelinePlot;

const dbHref = "https://app-v2-analytics-default-rtdb.firebaseio.com/";

const fetchClientIds = async (selectedDateRange: Daterange, onlyFetchFeedbackEntries: boolean) => {
  // const response = await fetch(`${dbHref}.json?shallow=true`);
  const response = await fetch(`${dbHref}.json`);
  const data = await response.json();
  const filteredData = filterDataByDateRange(data, selectedDateRange);
  if (onlyFetchFeedbackEntries) {
    return Object.keys(filterOnlyFeedbackEntries(filteredData));
  }
  return Object.keys(filteredData);
}

const filterOnlyFeedbackEntries = (data: any) => {
  const filteredData: any = {};

  Object.keys(data).forEach((key) => {
    const filteredEntries = Object.entries(data[key])
      .filter(([eventKey, entry]: [string, any]) => {
        return entry.type.includes("feedback");
      })
      .reduce((acc: any, [eventKey, entry]: [string, any]) => {
        acc[eventKey] = entry;
        return acc;
      }, {});

    if (Object.keys(filteredEntries).length > 0) {
      filteredData[key] = filteredEntries;
    }
  });

  return filteredData;
}

const filterDataByDateRange = (data: any, selectedDateRange: Daterange) => {
  const startDate = new Date(selectedDateRange.start);
  const endDate = new Date(selectedDateRange.end);
  const filteredData: any = {};

  Object.keys(data).forEach((key) => {
    const filteredEntries = Object.entries(data[key])
      .filter(([eventKey, entry]: [string, any]) => {
        const date = new Date(entry.timestamp);
        return date >= startDate && date <= endDate;
      })
      .reduce((acc: any, [eventKey, entry]: [string, any]) => {
        acc[eventKey] = entry;
        return acc;
      }, {});

    if (Object.keys(filteredEntries).length > 0) {
      filteredData[key] = filteredEntries;
    }
  });

  return filteredData;
}

const fetchClientData = async (clientId: string) => {
  const response = await fetch(`${dbHref}${clientId}.json`);
  const data = await response.json();
  return data;
}