import { FirebaseConfig } from "@robotical/analytics-gatherer/dist";
 
const appConfig:FirebaseConfig= {
    apiKey: "AIzaSyA51XE7fXHzsfLdWUULj6P4wpEdE-HIbY4",
    authDomain: "marty-app-analytics.firebaseapp.com",
    databaseURL: "https://marty-app-analytics-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "marty-app-analytics",
    storageBucket: "marty-app-analytics.appspot.com",
    messagingSenderId: "360434117994",
    appId: "1:360434117994:web:45bb18ac2f6a6e42a44913",
    measurementId: "G-G9BRPK12XZ",
    email: "",
    password: ""
};
const learningSiteConfig:FirebaseConfig = {
    apiKey: "AIzaSyA_LWFZcTyYNaFyxmB4OPc6SmgU8Zzs9M0",
    authDomain: "learning-site-analytics.firebaseapp.com",
    databaseURL: "https://learning-site-analytics-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "learning-site-analytics",
    storageBucket: "learning-site-analytics.appspot.com",
    messagingSenderId: "375076108196",
    appId: "1:375076108196:web:ec82ddb1a987f418623331",
    email: "dev@robotical.io",
    password: "martyRocks"
};
const robotConfig:FirebaseConfig = {
    apiKey: "AIzaSyA5U7tSsrs22ZPxHJGmYAJaSHPe0XtraBY",
    authDomain: "marty-bt-robot-analytics.firebaseapp.com",
    databaseURL: "https://marty-bt-robot-analytics-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "marty-bt-robot-analytics",
    storageBucket: "marty-bt-robot-analytics.appspot.com",
    messagingSenderId: "241250679762",
    appId: "1:241250679762:web:354e3e8ec282695c7aa60b",
    measurementId: "G-4GQ5Y27P36",
    email: "",
    password: ""
};
const processedDataConfig:FirebaseConfig = {
    apiKey: "AIzaSyDz2rDCiSqT_BCgkVbmUJZi-QpTeq1w47g",
    authDomain: "analytics-processed.firebaseapp.com",
    databaseURL: "https://analytics-processed-default-rtdb.firebaseio.com",
    projectId: "analytics-processed",
    storageBucket: "analytics-processed.appspot.com",
    messagingSenderId: "775956929723",
    appId: "1:775956929723:web:52d12614f4849b5e495d9e",
    email: "dev@robotical.io",
    password: "martyRocks"
};

export { appConfig, learningSiteConfig, robotConfig, processedDataConfig };