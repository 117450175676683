// Application
export const App_AdoptionCount = `
Shows the number of unique sessions over time. We can change the 'period' to see the number<br>
of unique sessions over a period of 7, 30 or 90 days. For example, if we select 7 days,<br> 
and there are 100 unique sessions on 7/7/2023, that means that 100 unique sessions<br> 
occurred between 1/7/2023 and 7/7/2023.`
export const App_RetentionRates = `
Percentage of users (based on device id) that used the application in the past and returned in the current [period].<br>
For example, if we select 30 days, and there is a 50% retention rate on 1/10/2023, that means<br>
that 50% of the users who used the application between 1/9/2023 and 1/10/2023 had also used<br>
the app at some point in the past.`
export const App_OsCountOverTime = `
Count of operating systems (iOS, Android, Webapp) used by users over time. For example, if we select<br>
7 days, and there are 100 iOS, 50 Android and 30 Webapp users on 7/7/2023, that means that 100 iOS,<br>
50 Android and 30 Webapp users used the platform between 1/7/2023 and 7/7/2023.
`;
export const App_DeviceModelCountOverTime = `
This plots shows the count of the operating systems (iOS, Android, Webapp) broken down by device model over time. <br>
`;
export const App_DeviceVersionCountOverTime = `
This plots shows the count of the operating systems (iOS, Android, Webapp) broken down by device version over time. <br>
`;

export const App_AppVersionCountOverTime = `
This plots shows the count of the app (Marty the Robot app) versions used by users over time, broken down by iOS and Android. <br>
(The webapp is always on the latest version).
`;

export const App_VisitedScreensOverTime = `
Shows the number of screen visits over time. We can change the 'period' to see the number of screen visits over a period of 7, 30 or 90 days. <br>
`

// Robot
export const Robot_AdoptionCount = `
Shows the number of unique robots (serial numbers) over time. We can change the 'period' to see the number<br>
of unique robots over a period of 7, 30 or 90 days. For example, if we select 7 days,<br> 
and there are 100 unique robots on 7/7/2023, that means that 100 unique robots<br> 
were used between 1/7/2023 and 7/7/2023.`;
export const Robot_RetentionRates = `
Percentage of robots that were used in the past and used again in the current [period].<br>
For example, if we select 30 days, and there is a 50% retention rate on 1/10/2023, that means<br>
that 50% of the robots that were used between 1/8/2023 and 1/9/2023 came back<br>
at some point between 1/9/2023 and 1/10/2023`;
export const Robot_ActiveUsersOverTime = `
Percentage of active users over time. That is, unique robots that have used the app in the last [period], devided by<br>
the total unique robots up to that point.`;
export const Robot_WarrantyActiveUsersOverTime = `
Percentage of robots with an active warranty service account over time.<br>
That is, unique robots that have an active warranty<br>
service account devided by the total unique robots up to that point.`;
export const Robot_ActiveUsersCountOverTime = `
Count of monthly active users over time. That is, the total unique robots that have used the app in the last [period].`;
export const Robot_MeanSessionDurationOverTime = `
The mean session duration over time. For example, if we select 7 days, then it shows the weekly mean session duration. <br>`;
export const Robot_MeanSessionDurationByTimezoneOverTime = `
The mean session duration over time broken down by timezone. For example, if we select 7 days, then it shows the weekly mean session duration. <br>
across different timezones.`;
export const Robot_VersionDistributionOfActiveUsers = `
Distribution of Marty versions used by the active users. Active users are defined as users who have used the app in the last 7 days.`;
export const Robot_FrequencyOfRobotSessions = `
Frequency of robot sessions over time broken down by timezone. We can also select the 'period' we're interested in<br>
i.e., the hours of the day, the days of the week, and the months of the year. <br>
Also, we can specify the time window we want to focus, for example, we can focus only on the sessions that occurred between 1/1/2023 and 1/5/2023;`;
export const Robot_RobotSessionsLinedUp = `
The count of the robot sessions lined up over time and broken down by timezone and RIC revision.<br>
For example, is we select All timezones, and Monthly period, we can see the number of the robot sessions<br>
in January, February, March, etc. for each RIC revision.`;
export const Robot_MostActiveTimezonesByRicRev = "Most active timezones by RIC REV";
export const Robot_UsageChangeRateOverTimeByRicRev = `
It shows the change range over time broken down by RIC revision. When the change rate is 0 (baseline),<br>
it means that the usage is stable. When the change rate is positive, it means that the usage is increasing,<br>
and when the change rate is negative, it means that the usage is decreasing.`;
export const Robot_MostActiveFWVersionOverTime = "Most active firmware version over time";
export const Robot_WarningMessagesOccurenciesByRicRevOverTime = "Warning messages occurencies by RIC REV over time";


export const AdoptionCountDescription = "Count of unique sessions over time (not cumulative)";
export const VisitedLessonsDescription = "Visited lessons in the Learning Site over time (not cumulative)";
export const DownloadedLessonsDescription = "Downloaded lessons in the Learning Site over time (not cumulative)";
export const OsCountBimonthlyDescription = "Bimonthly count of operating systems used by users";
export const iOSDeviceModelCountBimonthlyDescription = "Bimonthly count of iOS device models used by users";
export const AndroidDeviceModelCountBiweeklyDescription = "Biweekly count of Android device models used by users";
export const AndroidDeviceModelCountBimonthlyDescription = "Bimonthly count of Android device models used by users";
export const WebAppDeviceModelCountBiweeklyDescription = "Biweekly count of Web app device models used by users";
export const WebAppDeviceModelCountBimonthlyDescription = "Bimonthly count of Web app device models used by users";
export const iOSDeviceVersionCountBimonthlyDescription = "Bimonthly count of iOS app versions used by users";
export const AndroidDeviceVersionCountBiweeklyDescription = "Biweekly count of Android app versions used by users";
export const AndroidDeviceVersionCountBimonthlyDescription = "Bimonthly count of Android app versions used by users";
export const WebAppDeviceVersionCountBiweeklyDescription = "Biweekly count of Web app versions used by users";
export const WebAppDeviceVersionCountBimonthlyDescription = "Bimonthly count of Web app versions used by users";
export const iOSAppVersionCountBimonthlyDescription = "Bimonthly count of app versions used by iOS users";
export const AndroidAppVersionCountBiweeklyDescription = "Biweekly count of app versions used by Android users";
export const AndroidAppVersionCountBimonthlyDescription = "Bimonthly count of app versions used by Android users";
export const VisitedScreensBimonthlyDescription = "Bimonthly count of screens visited by users";
export const BiweeklyFwVersionCountDescription = "Biweekly count of firmware versions used by users";
export const BimonthlyFwVersionCountDescription = "Bimonthly count of firmware versions used by users";
export const FirmwareVersionCountOverTimeDescription = "Count of firmware versions used by users over time (not cumulative)";
export const WarrantyServiceWarningsPercentageOverTime = "Percentage of warnings associated with serial numbers that have an active warranty service account over time (not cumulative)";

// Marty Blocks
export const MostUsedBlocksDescription = "Most used blocks in Marty Blocks";
export const MostUsedBlocksOverTimeDescription = "Most used blocks in Marty Blocks over time";

// Learning Site
export const LSActiveUsageOverTimeDescription = "Percentage of active sessions over time (not cumulative)";
export const FilterUsageOverTimeDescription = "Percentage of sessions in which a filter was used over time (not cumulative)";
export const MostUsedFiltersDescription = "Most used filters in the Learning Site";
export const MostUsedFiltersOverTimeDescription = "Most used filters in the Learning Site over time";
export const MostUsedSearchTermsDescription = "Most used search terms in the Learning Site";
export const MostUsedSearchTermsOverTimeDescription = "Most used search terms in the Learning Site over time";
export const SearchBarUsageOverTimeDescription = "Percentage of sessions in which search bar was used over time (not cumulative)";
export const DropInActiveUsageOverTimeDescription = "This plots focuses only on the ACTIVE SESSIONS. New Users: users who have never used the site before; Returning Users: users who have used the site before; Adopted but didn't return: users who have used the site at least twice before, but haven't used it in the last period";
export const DropInLessonUsageOverTimeDescription = "This plots focuses only on the session where at least a lesson was used. New Users: users who have never used some lesson before; Returning Users: users who have used some lesson before; Adopted but didn't return: users who have used some lesson at least twice before, but haven't used another in the last period";

// Machine learning
export const MLActiveUsersOverTimeDescription = "Percentage of active sessions over time (not cumulative)";

// Sensors Dashboard
export const SensorDashboardActiveUsersOverTimeDescription = "Percentage of active sessions over time (not cumulative)";

// Python Translator
export const PythonTranslatorActiveUsersOverTimeDescription = "Percentage of active sessions over time (not cumulative)";

// App 2 Testing
export const App2TestingTimelineDescription = "Timeline of the App 2 Testing user journey";