import Img1 from "../assets/images/landing-page-buttons/img1.png";
import Img2 from "../assets/images/landing-page-buttons/img2.png";
import Img3 from "../assets/images/landing-page-buttons/img3.png";
import MbImg from "../assets/images/landing-page-buttons/mb-img.png";
import MlImg from "../assets/images/landing-page-buttons/ml-img.png";
import SdImg from "../assets/images/landing-page-buttons/sens-dash-img.png";
import PtImg from "../assets/images/landing-page-buttons/python-translator-img.png";
import App2Img from "../assets/images/landing-page-buttons/img1.png";

type LinkType = {
    href: string;
    title: string;
    img: string;
  };
  
export const NAV_LINKS: LinkType[] = [
    { href: "/app", title: "Application sessions", img: Img3 },
    { href: "/robot", title: "Robot sessions", img: Img2 },
    { href: "/learning-site", title: "Learning site", img: Img1 },
    { href: "/marty-blocks", title: "Marty Blocks", img: MbImg},
    { href: "/machine-learning", title: "Machine Learning", img: MlImg},
    { href: "/sensors-dashboard", title: "Sensors Dashboard", img: SdImg},
    { href: "/python-translator", title: "Python Translator", img: PtImg},
    { href: "/app2-testing", title: "App 2 Testing", img: App2Img }
  ];
